import React, { useReducer, createContext } from "react";
import jwtDecode from "jwt-decode";
import { isArray } from "lodash";

const initialState = {
  user: null,
  business_lines: [],
  points: [],
};

const AuthContext = createContext({
  user: null,
  login: (userData) => { },
  logout: () => { },
});

function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    default:
      console.log(state)
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login(userData) {
    dispatch({
      type: "LOGIN",
      payload: userData,
    });
  }
  function logout() {
    dispatch({ type: "LOGOUT" });
    window.location.href = "/";
  }

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
