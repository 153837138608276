const { default: axios } = require("axios");
const URL = process.env.REACT_APP_SERVER_DOMAIN;
// const URL = "http://localhost:3980/"
const consumeGet = async (url, params, token) => {
  return await axios.get(URL + url, {
    params: params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
export { URL, consumeGet };
