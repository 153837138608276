import React, { useEffect, useContext, useState, Fragment } from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    FormControlLabel,
    FormGroup,
    Grid,
    Input,
    Modal,
    Table,
    TableContainer,
    TextField,
    Typography,
    TableBody,
    TableCell,
    TableRow,
    CardContent,
    TableHead,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import "./pagination.css";
import axios from "axios";
import { consumeGet, URL } from "./utils/constants";
import { AuthContext } from "./context";
import {
    Add,
    AssignmentLate,
    AttachFile,
    Delete,
    Edit,
    History,
    RemoveRedEye,
    SetMealRounded,
} from "@mui/icons-material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import moment from "moment";
import NotificationsIcon from "@mui/icons-material/Notifications";
export default function Points() {
    const theme = useTheme();
    const [selected, setSelected] = useState(false);
    const { user } = useContext(AuthContext);
    const [errors, setErrors] = useState(null);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([])
    const [search, setSearch] = useState("")
    const countPerPage = 10;
    const [lines, setLines] = useState([])
    const [add, setAdd] = useState({
        active: 0,
        id: null,
        name: "",
        id_line: null,
        des: "",
        port: "",
        rout: "",
        acce: 1,
        qr: "",
        noti: 0,
        mail: ""
    })
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        if (search.trim() !== "") {
            let result = data.filter((o) => {
                const regex = new RegExp("^" + search, "i");
                return regex.test(o.qr) | regex.test(o.id_line) | regex.test(o.acce);
            });
            setFilteredData([...result]);
            const to = countPerPage * currentPage;
            const from = to - countPerPage;
            setCollection(cloneDeep(result.slice(from, to)))
        } else {
            const to = countPerPage * currentPage;
            const from = to - countPerPage;
            setFilteredData([...data]);
            setCollection(cloneDeep(data.slice(from, to)))
        }
    }, [search, data]);
    const getData = async () => {

        try {
            const result = await axios.get(
                `${URL}points`,
                {
                    headers: {
                        Authorization: "Bearer " + user.token,
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            if (result.status === 200) {
                setData([...result.data]);
            } else {
                console.log(result.data)
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        const getLines = async () => {

            try {
                const result = await axios.get(
                    `${URL}business_lines`,
                    {
                        headers: {
                            Authorization: "Bearer " + user.token,
                            "Access-Control-Allow-Origin": "*",
                        },
                    }
                );
                if (result.status === 200) {
                    setLines([...result.data]);
                } else {
                    console.log(result.data)
                }
            } catch (err) {
                console.log(err)
            }
        }
        getLines();
        getData();
    }, []);
    const [collection, setCollection] = useState(
        cloneDeep(filteredData.slice(0, countPerPage))
    );
    const updatePage = (p) => {
        setCurrentPage(p);
        const to = countPerPage * p;
        const from = to - countPerPage;
        setCollection(cloneDeep(filteredData.slice(from, to)));
    };
    const [modal, setModal] = useState({
        open: false,
        toConfirm: null,
        description: "",
    });
    const [deleteModal, handleDeleteModal] = useState(null);
    const handleConfirm = async (type, time) => {
        // const userId = await OneSignal.getUserId();
        console.log(add)
        const params = {
            active: add.active,
            name: add.name,
            id_line: add.id_line,
            des: add.des,
            port: add.port,
            rout: add.rout,
            acce: add.acce,
            qr: add.qr,
            noti: add.noti,
            mail: add.mail
        };
        let result;
        if (add.editMode) {
            result = await axios
                .put(`${URL}points/${add.id}`, params, {
                    headers: {
                        Authorization: "Bearer " + user.token,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .catch((err) =>
                    setErrors(err.response.data.errors)
                );
        }
        else {
            result = await axios
                .post(`${URL}points`, params, {
                    headers: {
                        Authorization: "Bearer " + user.token,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                .catch((err) =>
                    setErrors(err.response.data.errors)
                );
        }
        try {
            if (result.status === 200) {
                setSelected(false);
                setAdd({
                    active: 0,
                    id: null,
                    name: "",
                    id_line: null,
                    des: "",
                    port: "",
                    rout: "",
                    acce: 1,
                    qr: "",
                    noti: 0,
                    mail: "",
                    editMode: false
                });
                getData();
            } else {
                setErrors(result.data.errors);
            }
        } catch (err) {
            console.log(err.response);
        }
    };
    const handleConfirmPick = async (id, orderId) => {
        try {
            setModal({
                description: `Order No. ${orderId} will go to "To Pick up"`,
                open: true,
                toConfirm: id,
            });
        } catch (err) {
            console.log(err);
        }
    };
    const handleErase = async () => {
        const config = {
            headers: {
                Authorization: "Bearer " + user.token,
            },
        };

        const result = await axios.delete(
            `${URL}points/${deleteModal}`,
            config
        ).catch((err) => {
            handleDeleteModal(null)
            setErrors(err.response.data.errors)
        }
        );

        if (result.status === 200) {
            handleDeleteModal(null);
            getData();


        } else {
            handleDeleteModal(null)

            setErrors(result.data.errors)
        }
    };
    return (
        <Box style={{ padding: 20 }}>
            {selected && (
                <Dialog fullWidth maxWidth="md" open={true}>
                    <DialogTitle style={{ textAlign: "center" }}>{add.editMode ? "Editar" : "Agregar"} Punto</DialogTitle>
                    <DialogContent>
                        <Box
                            width="90%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <Grid container>
                                    <Grid xs={5}>
                                        <FormControl sx={{ m: 1, width: "100%" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 8, }}>

                                                <Typography style={{ marginRight: 18, marginLeft: 8 }}>LN</Typography>
                                                <Select
                                                    labelId="select"
                                                    id="demo-simple-select-helper"
                                                    value={add.id_line}
                                                    fullWidth
                                                    label="Option List Group"
                                                    onChange={(e) => {
                                                        setAdd({
                                                            ...add,
                                                            id_line: e.target.value,
                                                        });
                                                    }}
                                                >
                                                    {lines.map((e) => (
                                                        <MenuItem key={e.id_line} selected={add.id_line === e.id_line} value={e.id_line}>{e.name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={2}></Grid>
                                    <Grid xs={5}>
                                        <FormControlLabel
                                            control={
                                                <TextField
                                                    style={{
                                                        marginLeft: 24,
                                                        boxShadow: "rgb(116 116 116) 1px 0px 1px",
                                                        borderRadius: 4,
                                                        marginTop: 16
                                                    }}
                                                    fullWidth
                                                    inputProps={{ style: { borderRadius: 18, } }}
                                                    value={add.id_line}
                                                    disabled={true}
                                                />
                                            }
                                            label="ID"
                                            labelPlacement="start"
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <TextField
                                            style={{
                                                marginLeft: 24,
                                                boxShadow: "rgb(116 116 116) 1px 0px 1px",
                                                borderRadius: 4,
                                            }}
                                            fullWidth
                                            inputProps={{ style: { borderRadius: 18 } }}
                                            value={add.mail}
                                            onChange={(e) =>
                                                setAdd({
                                                    ...add,
                                                    mail: e.target.value,
                                                })
                                            }
                                        />
                                    }
                                    label="Email"
                                    labelPlacement="start"
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <TextField
                                            style={{
                                                marginLeft: 24,
                                                boxShadow: "rgb(116 116 116) 1px 0px 1px",
                                                borderRadius: 4,
                                            }}
                                            fullWidth
                                            inputProps={{ style: { borderRadius: 18 } }}
                                            value={add.qr}
                                            onChange={(e) =>
                                                setAdd({
                                                    ...add,
                                                    qr: e.target.value,
                                                })
                                            }
                                        />
                                    }
                                    label="QR"
                                    labelPlacement="start"
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <TextField
                                            style={{
                                                marginLeft: 24,
                                                boxShadow: "rgb(116 116 116) 1px 0px 1px",
                                                borderRadius: 4,
                                            }}
                                            fullWidth
                                            inputProps={{ style: { borderRadius: 18 } }}
                                            value={add.port}
                                            onChange={(e) =>
                                                setAdd({
                                                    ...add,
                                                    port: e.target.value,
                                                })
                                            }
                                        />
                                    }
                                    label="Puerto"
                                    labelPlacement="start"
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <TextField
                                            style={{
                                                marginLeft: 24,
                                                boxShadow: "rgb(116 116 116) 1px 0px 1px",
                                                borderRadius: 4,
                                            }}
                                            fullWidth
                                            inputProps={{ style: { borderRadius: 18 } }}
                                            value={add.rout}
                                            onChange={(e) =>
                                                setAdd({
                                                    ...add,
                                                    rout: e.target.value,
                                                })
                                            }
                                        />
                                    }
                                    label="Ruta"
                                    labelPlacement="start"
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 8, }}>

                                    <Typography style={{ marginRight: 18, marginLeft: 8 }}>Acceso</Typography>
                                    <Select
                                        labelId="select"
                                        id="demo-simple-select-helper"
                                        value={add.acce}
                                        fullWidth
                                        label="Option List Group"
                                        onChange={(e) => {
                                            setAdd({
                                                ...add,
                                                acce: e.target.value,
                                            });
                                        }}
                                    >
                                        <MenuItem
                                            selected={add.acce === 1 ? true : false}
                                            value={1}
                                        >
                                            Entrada
                                        </MenuItem>
                                        <MenuItem
                                            selected={!add.acce === 1 ? true : false}
                                            value={2}
                                        >
                                            Salida
                                        </MenuItem>
                                    </Select>
                                </div>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <FormControlLabel
                                    control={
                                        <TextField
                                            style={{
                                                marginLeft: 24,
                                                boxShadow: "rgb(116 116 116) 1px 0px 1px",
                                                borderRadius: 4,
                                            }}
                                            fullWidth
                                            inputProps={{ style: { borderRadius: 18 } }}
                                            value={add.des}
                                            onChange={(e) =>
                                                setAdd({
                                                    ...add,
                                                    des: e.target.value,
                                                })
                                            }
                                        />
                                    }
                                    label="Descripción"
                                    labelPlacement="start"
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 8, }}>

                                    <Typography style={{ marginRight: 18, marginLeft: 8 }}>Estado</Typography>
                                    <Select
                                        labelId="select"
                                        id="demo-simple-select-helper"
                                        value={add.active}
                                        fullWidth
                                        label="Option List Group"
                                        onChange={(e) => {
                                            setAdd({
                                                ...add,
                                                active: e.target.value,
                                            });
                                        }}
                                    >
                                        <MenuItem
                                            selected={add.active === 1 ? true : false}
                                            value={1}
                                        >
                                            Activo
                                        </MenuItem>
                                        <MenuItem
                                            selected={!add.active === 1 ? true : false}
                                            value={0}
                                        >
                                            Inactivo
                                        </MenuItem>
                                    </Select>
                                </div>
                            </FormControl>
                            <FormControl sx={{ m: 1, width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: 8, }}>

                                    <Typography style={{ marginRight: 18, marginLeft: 8 }}>Notificaciones</Typography>
                                    <Select
                                        labelId="select"
                                        id="demo-simple-select-helper"
                                        value={add.noti}
                                        fullWidth
                                        label="Option List Group"
                                        onChange={(e) => {
                                            setAdd({
                                                ...add,
                                                noti: e.target.value,
                                            });
                                        }}
                                    >
                                        <MenuItem
                                            selected={add.noti === 1 ? true : false}
                                            value={1}
                                        >
                                            Activo
                                        </MenuItem>
                                        <MenuItem
                                            selected={!add.noti === 1 ? true : false}
                                            value={0}
                                        >
                                            Inactivo
                                        </MenuItem>
                                    </Select>
                                </div>
                            </FormControl>
                        </Box>
                        {errors && (
                            <Card style={{ width: "80%", marginLeft: "10%" }}>
                                <CardContent>
                                    {Object.values(errors).map((e) => (
                                        <Typography color="#df4759" textAlign="center">
                                            *{e}
                                        </Typography>
                                    ))}
                                </CardContent>
                            </Card>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="inherit"
                            onClick={() => {
                                setSelected(false)
                                setAdd({
                                    active: 0,
                                    id: null,
                                    name: "",
                                    id_line: null,
                                    des: "",
                                    port: "",
                                    rout: "",
                                    acce: 1,
                                    qr: "",
                                    noti: 0,
                                    mail: "",
                                    editMode: false
                                })
                            }
                            }
                            variant="contained"
                        >
                            Cancelar
                        </Button>
                        <Button onClick={() => handleConfirm()} variant="contained">
                            {add.editMode ? "Editar" : "Añadir"}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}{
                deleteModal && (
                    <Dialog open={true}>
                        <Box style={{ padding: 12 }}>
                            <DialogTitle
                                style={{
                                    marginBottom: 20,
                                    textAlign: "center",
                                    fontSize: 20,
                                    fontWeight: 800,
                                }}
                            >
                                Eliminar
                            </DialogTitle>
                            <DialogContent>
                                <Typography style={{ marginBottom: 12 }}>
                                    ¿Estas seguro de eliminar esta Punto?
                                </Typography>
                            </DialogContent>
                            <DialogActions>
                                <Fragment>
                                    <Button
                                        color="inherit"
                                        onClick={() =>
                                            handleDeleteModal(null)
                                        }
                                        variant="contained"
                                        style={{ width: "50%" }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        style={{ width: "50%" }}
                                        onClick={() => handleErase()}
                                        variant="contained"
                                    >
                                        Eliminar
                                    </Button>
                                </Fragment>
                            </DialogActions>
                        </Box>
                    </Dialog>
                )
            }

            <Dialog open={modal.open}>
                <Box style={{ padding: 12 }}>
                    <DialogTitle
                        style={{
                            marginBottom: 20,
                            textAlign: "center",
                            fontSize: 20,
                            fontWeight: 800,
                        }}
                    >
                        ¡Great!
                    </DialogTitle>
                    <DialogContent>
                        <Typography style={{ marginBottom: 12 }}>
                            {modal.description}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {modal.toConfirm ? (
                            <Fragment>
                                <Button
                                    color="inherit"
                                    onClick={() =>
                                        setModal({ open: false, toConfirm: null, description: "" })
                                    }
                                    variant="contained"
                                    style={{ width: "50%" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{ width: "50%" }}
                                    onClick={() => handleErase()}
                                    variant="contained"
                                >
                                    Ok
                                </Button>
                            </Fragment>
                        ) : (
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Button
                                    style={{ width: "50%" }}
                                    onClick={() =>
                                        setModal({ open: false, toConfirm: null, description: "" })
                                    }
                                    variant="contained"
                                >
                                    Ok
                                </Button>
                            </Box>
                        )}
                    </DialogActions>
                </Box>
            </Dialog>
            {errors && (
                <Dialog
                    open={true}
                    onClose={() => setErrors(null)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {Array.isArray(errors) ? errors.map((e) => (
                                <Typography>{e.param}: {e.msg}</Typography>
                            )) : Object.values(errors).map((v) => (
                                <Typography>* {v}</Typography>
                            ))}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setErrors(null)}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Box>
                <Grid container>
                    <Grid xs={12} style={{ marginBottom: 14 }}>
                        <Typography style={{ fontSize: 21, color: theme.palette.primary.main, textAlign: "center", fontWeight: 800 }}>
                            Punto
                        </Typography>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={6} style={{ marginBottom: 14, justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setSelected(true)
                                setAdd({
                                    active: 0,
                                    id: null,
                                    name: "",
                                    id_line: null,
                                    des: "",
                                    port: "",
                                    rout: "",
                                    acce: 1,
                                    qr: "",
                                    noti: 0,
                                    mail: "",
                                    editMode: false
                                })
                            }}
                        >
                            Crear +
                        </Button>
                    </Grid>
                    <Grid xs={6} style={{ marginBottom: 14, justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <Box>
                            <TextField placeholder="Buscar" value={search} onChange={(e) => setSearch(e.target.value)} />
                        </Box>
                    </Grid>
                </Grid>
                {collection.length > 0 ? (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>

                                <TableCell>
                                    <Typography fontWeight={800}>ID LN</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>QR</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>Puerto</Typography>
                                </TableCell>
                                <TableCell>                                    <Typography align="center" fontWeight={800}>Ruta</Typography></TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>Acceso</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>Descripcion</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>Status</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>Notificaciones</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>F. Inicio</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight={800}>H. Inicio</Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableHead>
                            <TableBody>
                                {collection.map((o) => (
                                    <TableRow hover>
                                        <TableCell>{o.id_line}</TableCell>
                                        <TableCell>{o.qr}</TableCell>
                                        <TableCell>
                                            {o.port}
                                        </TableCell>
                                        <TableCell>
                                            {o.rout}
                                        </TableCell>
                                        <TableCell>
                                            {o.acce === 1 ? "Entrada" : "Salida"}
                                        </TableCell>
                                        <TableCell>
                                            {o.des}
                                        </TableCell>
                                        <TableCell><div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography>
                                            {
                                                o.statu ? "Activo" : "Inactivo"
                                            }
                                        </Typography>
                                            <div style={{ width: 12, height: 12, backgroundColor: o.statu ? "lightgreen" : "red", marginLeft: 5, borderRadius: 50 }}></div>
                                        </div>
                                        </TableCell>
                                        <TableCell><div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Typography>
                                            {
                                                o.noti ? "Activo" : "Inactivo"
                                            }
                                        </Typography>

                                            <div style={{ width: 12, height: 12, backgroundColor: o.noti ? "lightgreen" : "red", marginLeft: 5, borderRadius: 50 }}></div>
                                        </div>
                                        </TableCell>
                                        <TableCell>{o.dat.split("T")[0]}</TableCell>
                                        <TableCell>{o.hou}</TableCell>
                                        <TableCell>
                                            <div style={{ display: "flex" }}>
                                                <Button onClick={() => {
                                                    setSelected(true)
                                                    setAdd({
                                                        active: o.statu,
                                                        name: o.name,
                                                        id_line: o.id_line,
                                                        des: o.des,
                                                        id: o.id,
                                                        port: o.port,
                                                        rout: o.rout,
                                                        acce: o.acce,
                                                        qr: o.qr,
                                                        noti: o.noti,
                                                        mail: o.mail,
                                                        editMode: true
                                                    })
                                                }}>

                                                    <Edit />
                                                </Button>
                                                <Button onClick={() => handleDeleteModal(o.id)}>

                                                    <Delete />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box
                        width="100%"
                        height={400}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <AssignmentLate style={{ fontSize: 92 }} />
                        <Typography>No se ha creado ninguna Puntos</Typography>
                    </Box>
                )}
                <Box
                    marginTop={3}
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Pagination
                        pageSize={countPerPage}
                        onChange={updatePage}
                        current={currentPage}
                        total={data.length}
                    />
                </Box>
            </Box>
        </Box >
    );
}
