import React, { useContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Box, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Login from "./Login";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { AuthContext, AuthProvider } from "./context";
import BusinessLines from "./Business_line";
import Points from "./Points";
import { ExitToApp } from "@mui/icons-material";
// import OneSignal from "react-onesignal";

// OneSignal.init({ appId: "7f1954f1-5862-4118-b57f-1780e1d21193" });
function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <MainRouter />
      </AuthProvider>
    </ThemeProvider>
  );
}
function MainRouter() {
  const { user, logout } = useContext(AuthContext);
  const [clients, setClients] = useState(false)
  if (!user) {
    return <Login />;
  }
  return <div>
    <div style={{ marginTop: 20, justifyContent: "space-between", marginLeft: "15%", marginRight: "15%", display: "flex" }}>
      <Button onClick={() => setClients(false)} variant="contained">Lineas de negocio</Button>
      <Button onClick={() => setClients(true)} variant="contained">Clientes</Button>
      <Button onClick={() => logout()}><ExitToApp /></Button>
    </div>
    {
      !clients ? <BusinessLines /> : <Points />
    }

  </div>;
}
export default App;